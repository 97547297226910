import api from '../api/api';

const user = {
	"formProfiles": {},
	
	"init": function(){
		user.bindEvents();
	},

	"bindEvents": function(){
		$('body').on('submit', '[data-user-form]', function (e) {
			e.preventDefault();
			var action = $(this).attr('data-user-form');
			user.saveFormData(action, this);
		});

		$('[data-show-login]').on('click', function (e) {
			e.preventDefault();
			$('#forgot-step1').show();
			$('#forgot-thanks').hide();
			$('#forgot-password').fadeOut(0);
			$("#loginForm").fadeIn(350);
			$('#login-modal').removeClass('in-progress');
		});

		$('[data-refocus-onchange]').on('change', function (e) {
			e.preventDefault();
			$(this).trigger('blur');

			const $el = $("input[name='"+$(this).attr('data-refocus-onchange')+"']");
			$('.select2-container-active').removeClass('select2-container-active');

			if ($el.length){
				window.setTimeout(()=>{
					$el.get(0).focus();
				},50);
				
			}
		});
	},

	"doRegister": function(){
		$('#register-error').text('');
		$('#register-modal').addClass('in-progress');
		api.register(user.formProfiles["register"], user.registerResponse);
	},
	
	"doLogin": function(){
		$('#login-error').text('');
		$('#login-modal').addClass('in-progress');
		api.login(user.formProfiles["login"], user.loginResponse);
		
	},

	"doResetPassword": function(){
		$('#reset-error').text('');
		$('#login-modal').addClass('in-progress');
		api.passwordReset(user.formProfiles["reset"], user.resetResponse);
	},

	"doChangePassword": function () {
		$('#changepassword-error').text('');
		$('#reset-error').text('');
		api.changePassword(user.formProfiles["changePassword"], user.changePasswordResponse);
    },

	"loginResponse": function(data){
		
		if (data.success){
			window.location.reload();
		} else {
			$('#login-modal').removeClass('in-progress');
			$('#login-error').text(data.message);
		}
	},

	"registerResponse": function(data){
		
			var errors = data.errors;
			
			if (errors !== null) {
				var error_fields = Object.keys(data.errors);
				error_fields.forEach(function (field, index) {

					$('#registerForm').find("input[name='" + field + "']").addClass('error');
					$('#registerForm').find("span[data-valmsg-for='" + field + "']").text(errors[field]);
				});
				$('#registerForm .progressStep').removeClass('active');
				var parentId = $('#registerForm').find("input[name='" + error_fields[0] + "']").parents('.progressStep').attr('id');
				$('#' + parentId).show().addClass('active');
				$('#registerForm').find('#progressbar li').removeClass('active done');
				$('#registerForm').find('#progressbar li[data-progress-id="' + parentId + '"]').addClass('active done');
				$('#register-modal').removeClass('in-progress');

				return;

			} else {
				$('#register-modal').addClass("done");

				document.getElementById('registerForm').reset();
				user.formProfiles["register"] = undefined;
				
				$('#registerForm .customSelect').val('').trigger('change');
				$('#registerForm .progressStep').hide().removeClass('active');
				$('#registerForm #stepOne').show().addClass('active');
				$('#registerForm').find('#progressbar li').removeClass('unlocked active done');
				$('#registerForm').find('#progressbar li[data-progress-id="stepOne"]').addClass('unlocked active done');
				$('#register-modal').removeClass('in-progress');

            }
	},

	"resetResponse": function(data){
		$('#login-modal').removeClass('in-progress');

		if (data == 200){
			$('#forgot-step1').hide();
			$('#forgot-thanks').show();
			user.formProfiles["reset"] = undefined;
		} else {
			$('#reset-error').text("There was an error processing your request");
		}
	},

	"changePasswordResponse": function (data) {
		if (data.success == true) {
			$('#change-password-modal').addClass('done');
			user.formProfiles["changePassword"] = undefined;
		} else {
			$('#change-password-error').text(data.message);
		}
	},

	"formDataToJson": function(formData){
		var object = {};
		formData.forEach(function(value, key){
			object[key] = value;
		});
		return object;
	},

	"saveFormData": function(type,form){
		if (typeof user.formProfiles[type] == "undefined"){
			user.formProfiles[type] = {};
		}

		user.formProfiles[type] = {
			...user.formProfiles[type],
			...user.formDataToJson(new FormData(form))
		}	

		if (type === "login") {
			user.doLogin()
		} else if (type === "register") {
			if (user.formProfiles["register"]["Industry"] === "Other"){
				user.formProfiles["register"]["AreaOfInterests"] = [$('[name = "AreaOfInterestsOther"]').val()]
			} else {
				user.formProfiles["register"]["AreaOfInterests"] = Array.prototype.map.call($('[name = "AreaOfInterests"]')[0].selectedOptions, function (x) { return x.value })
			}

			delete user.formProfiles["register"]["AreaOfInterestsOther"];
			
			user.doRegister();
		} else if (type == "reset") {
			user.doResetPassword();
		} else if (type == "changePassword") {
			user.doChangePassword();
        }

	}
}

export default user;
