var MainNavigation = {
	"scrollHeader" : null,
	"savedPos": null,
	"init": function(){
	  MainNavigation.bindEvents();
	  MainNavigation.scrollHeader = require("./scrollHeader");
	  MainNavigation.scrollHeader.init($('[data-component-id="main-navigation"]')[0]);

	  window.addEventListener("scroll", function(){
		  if ($("body").hasClass("show-cookies")){
			  var cookiesHeight = $('#cookies-notification').outerHeight();
			  if ($(window).scrollTop() <= cookiesHeight) {
				$('[data-component-id="main-navigation"]').css({'position' : 'absolute', 'top': '0', 'transform': 'none'});
			  } else {
				$('[data-component-id="main-navigation"]').css({'position': 'fixed', 'top': '0', 'transform': ''});
			  }
		  }
	  });


	},
	"openMenu": function(){
		MainNavigation.savedPos = $("html").scrollTop();
		$('#site>main').css({ transform: 'translateY(-'+MainNavigation.savedPos+'px)' });
		$("body").addClass("menu-open");

	},
	"closeMenu": function(){		
		$("body").removeClass("menu-open menu-l1 menu-l2");
		$('#site>main').css({ transform: 'translateY(0px)' });
		$('.block.menu .selected').removeClass('selected');
		$('.block.menu .leaving').removeClass('leaving');
		$("html").scrollTop(MainNavigation.savedPos);
	},
	"bindEvents": function(){
	
		$(document).on("click touchstart", function(e) {
			 if ($(e.target).closest('.dd').length === 0 && $(e.target).closest(".menu").length === 0) {
				 if ($("body").hasClass("menu-open")) {
					 MainNavigation.closeMenu();
				 }
				 $("nav > ul > .dd").removeClass("selected");
			 }
			 if ($(e.target).closest('.order_dd').length === 0) {
				 $(".order_dd").removeClass("open");
			 }
		 });
		 
		 // Dropdowns
		 $(document).on("click.menu", "nav > ul > .dd > a", function() {
			 var item = $(this);
			 $("nav > ul > .dd").filter(function() {
				 return $(this).index() !== item.parent().index();
			 }).removeClass("selected");
			 if ($("body").hasClass("menu-open")) {
				 $("body").addClass("menu-l1");
			 }
			 $(".menu-p1 > li, .menu-s1 > li").removeClass("leaving");
			 $(".menu-p2 > li, .menu-s2 > li").removeClass("selected");
			 $(this).parent().toggleClass("selected");
		 });
 
		 $(document).on("click.submenu", ".menu-p2 > .dd > a", function() {
			 $(".menu-p2 > li").removeClass("selected");
			 if ($("body").hasClass("menu-open")) {
				 $("body").addClass("menu-l2");
			 }
			 if ($(this).parent().hasClass("dd")) {
				 $(this).parent().addClass("selected");
			 }
		 });
 
		 $(document).on("click", ".menu-toggle", function(e) {
			 if ($("body").hasClass("menu-open")) {
				MainNavigation.closeMenu();
			 } else {
				 $("body").removeClass("search-open");
				 MainNavigation.openMenu();
			 }
		 });
		 
		 $(document).on("click", ".search-toggle", function(e) {
			 if ($("body").hasClass("search-open")) {
				$("body").removeClass("search-open");
			 } else {
				MainNavigation.closeMenu();
				$("body").addClass("search-open");
			 }
		 });
 
				 
 
		 $(document).on("click", "nav .back", function(e) {
			 if ($(".menu-p2 .selected, .menu-s2 .selected").length) {
				 $(".menu-p2 .selected, .menu-s2 .selected").addClass("leaving");
				 $(".menu-p2 .selected, .menu-s2 .selected").removeClass("selected");
				 $("body").removeClass("menu-l2");
			 } else {
				 $(".menu-p1 .selected, .menu-s1 .selected").addClass("leaving");
				 $(".menu-p1 .selected").removeClass("selected");
				 $("body").removeClass("menu-l1");
			 }
		 });
	},

   } 
   
   module.exports = MainNavigation;