import '../libs/ejs.min.js';
import api from '../api/api.js';
import Language from '../misc/language';
import URLParser from '../misc/url-parser';
import Pagination from "../misc/pagination";

class Applications {
	constructor() {
		this.cache = {
			filters: undefined,
			results: undefined
		};
		this.lang = {};
		this.searchParams = this.initState();
		Language.loadLanguage("en", (lang) => {
			this.lang = lang;
			this.buildFilterList();
			this.bindEvents();
		});

		this.doSearch(true);
	}

	objToArray(obj) {
		if (obj == null) return;
		return Object.values(obj) || [];
	}
	initState() {
		var urlOptions = URLParser.parseURL()[1];
		return {
			"membershipId": urlOptions.membershipId || null,
			"page": urlOptions.page || 1,
			"limit": urlOptions.limit || 9,
			"searchOrder": urlOptions.searchOrder || "ASC",
			"caseStudyApplicationId": urlOptions.caseStudyApplicationId || null,
			"productId": urlOptions.productId || null,
			"gradeId": urlOptions.gradeId || null,
			"keywords": urlOptions.keywords || "",
			"industries": this.objToArray(urlOptions.industries) || [],
			"features": this.objToArray(urlOptions.features) || [],
			"sustainables": this.objToArray(urlOptions.sustainables) || [],
			"brands": this.objToArray(urlOptions.brands) || [],
			"applications": this.objToArray(urlOptions.applications) || []
        }
    }
	buildFilterList(){
		const filters = window.filtersList;
		const filterItems = Object.keys(filters);
		
		this.loadTemplate("filters", window.api.template_path + "applications-filters.html", (template) => {
			$('[data-filter-list]').html(ejs.render(template, { filters: filters, filterItems: filterItems, hasFilters: this.hasFilters(), state: this.searchParams, checkFilter: this.checkFilter, getFilterCount: this.getFilterCount, lang: this.lang }));
		});
	}

	hasFilters() {
		if (this.searchParams.keywords !== '') return true;

		if (this.searchParams.industries.length) return true;
		if (this.searchParams.features.length) return true;
		if (this.searchParams.sustainables.length) return true;
		if (this.searchParams.brands.length) return true;
		if (this.searchParams.applications.length) return true;
		return false;
	}

	bindEvents() {
		$('#app').on('click', '[data-bind="filter-title"]', function(e){
			$(this).parents('.filter').toggleClass('open');
			if ($(this).parents('.filter').hasClass('active-open')) {
				$('#filters .filter').removeClass('active-open');
			} else {
				$('#filters .filter').removeClass('active-open');
				$(this).parents('.filter').toggleClass('active-open');
			}
			
			e.stopPropagation();
		});

		$('#app').on('click', '[data-bind="filter-toggle-all"]', (e) => {
			var el = e.target;
			var section = $(el).parents(".filter-list-section");
			var deselect = $(section).find('[type="checkbox"]').filter(':checked').length > 0;

			$(section).find('[type="checkbox"]').each(function (index, el) {
				if (!deselect) {
					$(el).prop('checked', true);

				} else {
					$(el).prop('checked', false);
				}
			});

			if (deselect) {
				$(el).removeClass('deselect');
			} else {
				$(el).addClass('deselect');
			}
			this.filtersUpdated();
			e.stopPropagation();
		});

		$('#app').on('keydown', '[data-bind="filter-title"]', function(e){
			if (e.keyCode === 13) {
			  e.preventDefault();
			  $(this).trigger('click');
			}
		});

		$('#app').on('click', '[data-toggle]', function (e) {
			$(this).parents('.filter-accordion').toggleClass('open');
		});

		$('#app').on('click', '.filter-list', function(e){
			e.stopPropagation();
		});

		$('#app').on('click', '[data-search-page-prev]', (e) => {
			this.pagination("prev");
		});

		$('#app').on('click', '[data-search-page-next]', (e) => {
			this.pagination("next");
		});

		$('#app').on('click', '[data-set-page]', (e) => {
			const page = $(e.target).attr('data-set-page');
			this.pagination(page);	
		});

		$('body').on('click', function(){
			$('.filter').removeClass('active-open');
		});

		$('#app').on('change', '[data-sort-dropdown]', (e) => {
			this.reorder(e.target.value);
		});

		$('#app').on('click', '[data-clear-all-filters]', (e) => {
			this.resetSearch();
			this.doFilterSearch();
		});

		$('[data-search-form]').on('submit', (e) => {
			e.preventDefault();
			var term = $('[data-searchbox]').val();
			this.doKeywordSearch(term);
		});


		$('#app').on('click', '[data-apply-filters]', (e) => {
			this.doFilterSearch();
		});

		$('[data-casestudies-searchbutton]').on('click', (e) => {
			var term = $('[data-searchbox]').val();
			this.doKeywordSearch(term);
		});

		$('#app').on('change', '[data-filter]', (e) => {
			this.filtersUpdated();
		});

		$('#app').on('click', '[data-remove-searchterm]', (e) => {
			var keyword = $(e.target).attr('data-keyword');
			var newKeyword = this.searchParams.keywords.replace(keyword, '').trim();
		
			newKeyword = newKeyword.replace(/ +(?= )/g, '');
			this.searchParams.keywords = newKeyword;
			this.doSearch();
		});

		$('#app').on('click', '[data-remove-filter]', (e) => {
			var type = $(e.target).attr('data-filter');
			this.searchParams[type] = {};
			this.doSearch();
		});
	}

	loadTemplate(name, path, renderFunction) {
		if (typeof this.cache[name] !== 'undefined') {
			renderFunction(this.cache[name]);
			return;
		} else {
			var that = this;
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					that.cache[name] = this.responseText;
					renderFunction(this.responseText);
				}
			}      
			xhttp.open("GET", path, true);
			xhttp.send();
			return;
		}
	}

	getCheckboxValues(name) {
		var checkboxes = document.getElementsByName(name);
		var vals = [];
		
		for (var i=0, n=checkboxes.length;i<n;i++) 
		{
			if (checkboxes[i].checked) 
			{
				vals.push(checkboxes[i].value);
			}
		}
		
		return vals;
	}

	filtersUpdated(){
		$('[data-apply-filters]').removeAttr('disabled');
	}

	updateFilters() {
		$('[data-filterset]').each((i, filter)=>{
			var id = $(filter).attr('id');
			var set = $(filter).attr('data-filterset');

			this.searchParams[set] = this.getCheckboxValues(id);
		});
		this.doSearch(true);
	}

	writeToURL() {
		URLParser.setURL('', this.searchParams);
	}

	checkFilter(filterId, filterListObj) {
		const filterList = Object.values(filterListObj);
		return (filterList.indexOf(filterId) > -1);
    }

	doKeywordSearch(term){
		this.searchParams.keywords = term;
		this.doSearch(true);
	}

	doFilterSearch(){
		this.updateFilters();
	}

	pagination(direction) {
		if (direction==="next"){
			this.searchParams.page = Number(this.searchParams.page) + 1;
		} else if (direction === "prev"){
			this.searchParams.page = Number(this.searchParams.page) - 1;
		}

		else if (!isNaN(direction)){
			this.searchParams.page = Number(direction);
		}

		this.doSearch(false);
	}

	reorder(order) {
		if (order==="ASC"){
			this.searchParams.searchOrder = "ASC";
		} else if (order === "DESC"){
			this.searchParams.searchOrder = "DESC";
		}
		this.doSearch(true);
	}

	resetSearch() {
		this.searchParams = {
			"membershipId": null,
			"page": 1,
			"limit":9,
			"searchOrder": "ASC",
			"caseStudyApplicationId": null,
			"productId": null,
			"gradeId": null,
			"keywords": "",
			"industries": [],
			"features": [],
			"sustainables": [],
			"brands": [],
			"applications": []
		};
		this.doSearch(true);
	}

	doSearch(resetPage) {
		this.writeToURL();
		if (resetPage){
			this.searchParams.page = 1;
		}
		api.doApplicationSearch(this.searchParams, (data) => {
			this.renderResults(data);
		});
		this.buildFilterList();
	}

	renderResults(data){
		$('[data-searchbox]').val(this.searchParams.keywords);
		$('[data-apply-filters]').attr('disabled', 'disabled');
		this.buildFilterList();
		const pagination = new Pagination(data.page,Math.ceil(data.total/data.limit));

		this.loadTemplate("results", window.api.template_path + "applications-results.html", (template) => {
			$('[data-application-results]').html(ejs.render(template, { data: data, lang: this.lang, pagination: pagination }));
			$('[data-sort-dropdown]').select2({
				minimumResultsForSearch: -1
			});
		});	
	}

	getFilterCount(filterState) {
		var items = Object.values(filterState);
		if (items == undefined) {
			return 0;
		}
		return Array.isArray(items) ? items.length : 1;
	}
}

export default Applications;