import Cookies from 'js-cookie';
import loadjs from 'loadjs';

var GDPRCookies = {
	cookie : {},
	"marketing_cookie_name" : "cookies_marketing",
	"statistics_cookie_name": "cookies_statistical",
	"init": function(S){
		const defaultValues = {
			"cookies_accepted": false,
			"cookies_marketing": window.marketing || false,
			"cookies_statistical": window.statistical || false
		}

		const gdpr = window.gdpr || true;
		
	

		this.cookie = Cookies.withAttributes({ path: '/', secure: true, expires: 365 });

		//set default cookie values
		const cookiePreferences = this.setDefaultValues(defaultValues);
	
		//handle cookie preferences form
		if( document.querySelector('[data-cookies-form]')){
			this.setPostForm(cookiePreferences);
		}

        if (cookiePreferences[GDPRCookies.marketing_cookie_name] == "true" || cookiePreferences[GDPRCookies.statistics_cookie_name] == "true"){
            this.loadTagManager(window.tagManagerId, cookiePreferences);
        }
       
		//logic to display the cookie popup panel
		if ((cookiePreferences["cookies_accepted"] !== "true") && gdpr){			
			$("body").addClass("show-cookies");
            $("#cookies-notification a").on("click", (e) => {
                const acceptedCookies = {
                    "cookies_accepted": true,
                    "cookies_marketing": true,
                    "cookies_statistical": true
                }
                this.setValues(acceptedCookies);
                $("body").removeClass("show-cookies");
                this.loadTagManager(window.tagManagerId, acceptedCookies);
            });
		}
	},

	"setDefaultValues": function(defaultValues){

		const cookiesToSet = Object.keys(defaultValues);
		var ret = {}

		cookiesToSet.map((val) => {
			const cookieValue = this.cookie.get(val);
			if (cookieValue == undefined){
				this.cookie.set(val, defaultValues[val], { path: '/', secure: true });
				ret[val] = defaultValues[val];
			} else 
				ret[val] = cookieValue;

		});
		
		return ret;
	},
	"setValues": function(values){
		const cookiesToSet = Object.keys(values);
        let ret = {};

		cookiesToSet.map((val) => {
			this.cookie.set(val, values[val],{ path: '/', secure: true });
			ret[val] = values[val];
		});
	},
	"setFormCheckbox": function(form,cookiePreferences){

		var keys = Object.keys(cookiePreferences);
		keys.map((val) => {
			const field = form.querySelector(`[name="${val}"]`)
			if (!field) return;

			field.checked = cookiePreferences[val] === "true";
		});
	},
	"setPostForm": function(cookiePreferences){
		const form = document.querySelector('[data-cookies-form]');
		const options = [GDPRCookies.marketing_cookie_name, GDPRCookies.statistics_cookie_name];

		const self = this;
		this.setFormCheckbox(form,cookiePreferences);
		form.addEventListener('change', (e) => {
			$('.confirmation').addClass('hide');
		})
		form.addEventListener('submit', (e) => {
			e.preventDefault();
			const data = new FormData(e.target);
			for (const name of options) {
				self.cookie.set(name, data.get(name)=="on", { path: '/', secure: true });
			}
			$('.confirmation').removeClass('hide');
			return false;

		});
		
	},
	"fireGTMEvent": function(event, debug){	
		if (debug){
			console.log("Fire GTM Event: " + event);			
		}
		if(typeof window.dataLayer != 'undefined'){
			window.dataLayer.push({
				'event': event
			});
		}
	},
    "loadTagManager": function(gtmId, cookiePreferences){
        function callback(){
            const debug = window.location.href.indexOf("mrm") > -1;
            
            //fire marketing GTM tags if allowed.
            if (cookiePreferences[GDPRCookies.marketing_cookie_name]){									
                GDPRCookies.fireGTMEvent(GDPRCookies.marketing_cookie_name, debug);
            }

            //fire stats GTM tags if allowed.
            if (cookiePreferences[GDPRCookies.statistics_cookie_name]){					
                GDPRCookies.fireGTMEvent(GDPRCookies.statistics_cookie_name, debug);			
            }
        }

        if (!gtmId || gtmId === ""){
            return;
        } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
            loadjs(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`,'gtm',{
                async: true,  
                numRetries: 3
            });

            loadjs.ready('gtm', {
                'success': callback,
                'error': function(){
                    console.log("error loading gtm");
                }
            });
            
        }
    }
}

export default GDPRCookies;