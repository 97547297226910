const ContactUs = {
    "init": function(){
        //$.validator.setDefaults({ ignore: null });
        alert("HI");
        $(".app-contact-us .customSelect").change(function () {
            var form = $(this).closest("form");
            form.validate({
                ignore: null
            }).form();
        });
    }
   

}

export default ContactUs;