/* ========================= */
/* MRM Scroll header library */
/* ========================= */

/*
Simple library to detect scrolling, used for "sticky" headers etc

options : 

scrollHandlerMaxWidth,scrollHandlerMaxWidth  = only run scroll handler between two screen sizes (responsive)
showAtEnd = set a class when the user hits the end of the page
classes = set classes to add/remove when you do various things (scroll up, down, no scroll, page end)
addClassTo = element that recieves the class

if you need to get the direction for any reason, use sh.getDirection(), which will return "up","same", "down"
*/

sh = {
	options: {
		"scrollHandlerMinWidth": 0,
		"scrollHandlerMaxWidth": 800,
		"forceScrollHandler": true,
		"showAtEnd": true,		
		"scrollThreshold": 50,
		"classes": {
			"scrollingDown": "scroll-down",
			"scrollingUp": "scroll-up",
			"noScroll": "no-scroll",
			"endOfPage": "page-end",
			"topOfPage": "page-start"
		},
		"addClassTo": document.body		
	},	
	"elem": null,
	"active": false,
	"isAtBottom": false,
	"directions": ["up", "same", "down"],
	"lastScroll": 0,
	"scrollDirection": 0,	
	"scrollThreshold": 0,
	"init": function(elem, options){
		if (elem != null){
			if (options != undefined){
				sh.helpers.mergeOptions(sh.options, options);
			}
			
			sh.elem = elem;
			sh.lastScroll = sh.getScrollTop();				
			sh.addResizeHandler();
		}
	},
	"addScrollHandler": function(){		
		if (!sh.active){
			sh.active = true;
			window.addEventListener("scroll", sh.scrollEvent);
			sh.options.addClassTo.classList.add('scroll-active');			
		}		
	},	
	"onScrollChange": function(){
		
		if (sh.scrollDirection == 1) {
			sh.options.addClassTo.classList.remove(sh.options.classes.scrollingUp);
			sh.options.addClassTo.classList.add(sh.options.classes.scrollingDown);
		} else if (sh.scrollDirection == -1) {
			sh.options.addClassTo.classList.remove(sh.options.classes.scrollingDown);
			sh.options.addClassTo.classList.add(sh.options.classes.scrollingUp);
		}
	},
	"scrollEvent": function(){
		var st = sh.getScrollTop(), dir = sh.scrollDirection;

		sh.scrollThreshold += st - sh.lastScroll;
		
		if (st > sh.lastScroll){
			sh.scrollDirection = 1;
		} else {
			if (st == sh.lastScroll){
				sh.scrollDirection = 0;					
			} else {
				sh.scrollDirection = -1;
			}
		}		
		
		if (dir !== sh.scrollDirection){
			sh.scrollThreshold = 0;			
			
		} else if (Math.abs(sh.scrollThreshold) > sh.options.scrollThreshold){
			sh.onScrollChange();
		}

		if (st == 0) {
			sh.options.addClassTo.classList.add(sh.options.classes.topOfPage);
		} else {
			sh.options.addClassTo.classList.remove(sh.options.classes.topOfPage);
		}

		if (document.body.scrollHeight - st - window.outerHeight < 50)
		{
			sh.options.addClassTo.classList.add(sh.options.classes.endOfPage);
		} else {
			sh.options.addClassTo.classList.remove(sh.options.classes.endOfPage);
		}

		sh.lastScroll = st;		
	},
	"removeScrollHandler": function(){
		if (sh.active){	
			window.removeEventListener("scroll", sh.scrollEvent);
			sh.options.addClassTo.classList.remove(sh.options.classes.scrollingUp, sh.options.classes.scrollingDown);
			sh.options.addClassTo.classList.remove('scroll-active');
			sh.active = false;			
		}
	},
	"addResizeHandler": function(){

		function rh(){			
			var w = window.outerWidth, remove = false;
			
			if (w < sh.options.scrollHandlerMinWidth && !sh.options.forceScrollHandler){
				remove = true;
			} 
			if (w > sh.options.scrollHandlerMaxWidth && !sh.options.forceScrollHandler){
				remove = true;
			}
			
			if (remove && sh.active){				
				sh.removeScrollHandler();
			} else if (!remove && !sh.active) {
				sh.addScrollHandler();
			}		
		}
		window.addEventListener("resize", rh);
		
		rh();
	},
	"getScrollTop": function(){
		return document.documentElement.scrollTop || document.body.scrollTop;
	},
	"getDirection": function(){
		return sh.directions[sh.scrollDirection+1];
	},
	"helpers": {
		"mergeOptions": function(a,b){
			for(var key in b)
				if(b.hasOwnProperty(key))
					a[key] = b[key];
			return a;
		}
	}
}

module.exports = sh;