const NetZero = {
    "init": function(){
        this.bindEvents();
    },
    "bindEvents": function(){
        $('[data-select-path]').on('click', function(){
            const className= 'show-path'+$(this).attr('data-select-path');
            const $portfolio = $('.circular-product-portfolio');
            if ($portfolio.hasClass(className)){
                $portfolio.removeClass(className);
            } else {
                $portfolio.removeClass("show-path1 show-path2 show-path3").addClass(className);
            }
			
		});

    }
}

export default NetZero;