import api from '../api/api';

const FavoritesMenu = {
	"template": undefined,
	"maxNumFavs": 5,
	"currentFavs": [],
	"init": function(){
		window.getFavoritesClassName = FavoritesMenu.getFavoritesClassName;
		window.getFavoritesActive = FavoritesMenu.getFavoritesActive;

		if (!window.member){
			return;
		} else {
			FavoritesMenu.initEvents();
			FavoritesMenu.getFavorites(
				function(data){
					FavoritesMenu.currentFavs = FavoritesMenu.getGradeIds(data);
					FavoritesMenu.render(data);
					
					data.map(function(el){
						var $favitems = $('[data-favorite-toggle][data-grade-id="'+el.gradeId+'"]');
						if ($favitems.length > 0){
							$favitems.addClass('active');
							$favitems.find('.fa-heart').addClass('fa-solid').addClass('active').removeClass('disabled');
						}
					});
				}
			);
		}
	},

	"getFavorites": function(callback){
		api.getFavoriteGrades(callback);
	},

	"initEvents": function(){
		$('#app').on('click','[data-favorite-toggle]',function(){
			var id = $(this).attr('data-grade-id');
			
			if (!$(this).hasClass('active')) {
				FavoritesMenu.validateAddFavorite(function () {
					FavoritesMenu.addFavoriteById(id);
					$(this).addClass('active');
					$(this).find('.fa-heart').addClass('fa-solid');

				
				})
			} else {
				FavoritesMenu.removeFavoriteById(id);
				$(this).removeClass('active');
				$(this).find('.fa-heart').removeClass('fa-solid').removeClass('active');
				$('[data-favorite-toggle] i').removeClass('disabled');
			}
		});
		
		$('body').on('click', '[data-remove-fav]', function(){
			var id = $(this).attr('data-grade-id');
			FavoritesMenu.removeFavoriteById(id);
			$('[data-favorite-toggle] i').removeClass('disabled');
		});
	},

	"getFavoritesActive": function (id) {
		if (FavoritesMenu.currentFavs.indexOf(id) > -1) {
			return " active";
		}
		return "";
    },

	"getFavoritesClassName": function (id) {
		var className="fa-heart fa-regular";
		
		if (FavoritesMenu.currentFavs.indexOf(id) > -1) {
			className += " fa-solid active";

		} else {
			if (!(FavoritesMenu.currentFavs.length < FavoritesMenu.maxNumFavs)) {
				className += " disabled";
			}
        }
		return className;
	},

	"getGradeIds": function(data){
		var ids = [];
		data.forEach(function(item){
			ids.push(item.gradeId);
		});
		
		return ids;
	},

	"validateAddFavorite": function (callback) {
		if (this.currentFavs.length < FavoritesMenu.maxNumFavs) {
			callback();
		}
	},

	"addFavoriteById": function(id){
		api.addFavorite(id, FavoritesMenu.render)
		var $favitems = $('[data-favorite-toggle][data-grade-id="'+id+'"]');
			if ($favitems.length > 0){
				$favitems.addClass('active');
				$favitems.find('.fa-heart').addClass('fa-solid');
		}
		
	},

	"removeFavoriteById": function(id){
		api.removeFavorite(id, FavoritesMenu.render)
		var $favitems = $('[data-favorite-toggle][data-grade-id="'+id+'"]');
			if ($favitems.length > 0){
				$favitems.removeClass('active');
				$favitems.find('.fa-heart').removeClass('fa-solid');
		}
	},

	"render": function(data){
		FavoritesMenu.currentFavs = FavoritesMenu.getGradeIds(data);
		if (!(FavoritesMenu.currentFavs.length < FavoritesMenu.maxNumFavs)) {
			$('[data-favorite-toggle]:not(.active) i').addClass('disabled');
		}
		FavoritesMenu.loadTemplate(window.api.template_path + "favorites-menu.html", function(template){
			$('#favoritesMenu').html(ejs.render(template, {favs: data}));
			$('#contactMenu').removeClass('first');
			window.inlineSVG();
		});
	},

	"loadTemplate": function(template, callback) {
		if (typeof FavoritesMenu.template !== 'undefined') {
			callback(FavoritesMenu.template);
			return;
		} else {

			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					FavoritesMenu.template = this.responseText;
					callback(this.responseText);
				}
			}      
			xhttp.open("GET", template, true);
			xhttp.send();
			return;
		}
	},

}

export default FavoritesMenu;