import $ from 'jquery';
import api from '../api/api';
import MicroModal from 'micromodal';  // es6 module
import CurveData from '../misc/curve-data';

import { slugify } from '../misc/helpers';

const ProductPage = {
	"curveData": {},
	"init": function (compareTray) {
		ProductPage.bindEvents();
		ProductPage.buildInPageNav();
		ProductPage.scrollHandler();
		if (window.curveData !== null && window.curveData.length) {
			ProductPage.curveData = new CurveData(window.curveData,$('#chart'));
			ProductPage.loadCurveData();
        }
		$('[data-max-height]').find('.card').each(function () {
			if ($(this).innerHeight() > 500) {
				$('[data-max-height]').addClass('overflow');
			}
		});
		//new SortableTable($('table.sortable')[0]);
	},

	"bindEvents": function () {
		$('[data-wtb-search]').on('keyup', function () {
			ProductPage.whereToBuySearch($(this).val());
		});

		$('[data-region-select]').on('change', function () {
			var $parent = $(this).parents('[data-region-parent]');
			var selectedRegion = $(this).val();
			$parent.find('[data-region]').removeClass('active');
			$parent.find('[data-region="' + selectedRegion + '"]').addClass('active');
		});

		$('[data-whereToBuy-trigger]').on('click', function () {
			MicroModal.show('whereToBuy');
			ProductPage.renderDistributors();
		});

		$('[data-view-more-card]').on('click', function () {
			$(this).parents('.maxheight').toggleClass('show');
		});

		$('body').on('click', '[data-load-series]', function () {
			var type = $(this).attr('data-load-type');
			var subtype = $(this).attr('data-load-subtype');
			ProductPage.curveData.drawChart(type, subtype);
		});

		$(window).on('scroll', (e) => {
			this.scrollHandler(e);
		});

		$('[data-curve-selector]').on('change', function () {
			var $el = $(this).find(':selected');
			var type = $el.attr('data-type');
			var subtype = $el.attr('value');
			var curveDataId = $el.attr('data-curve-id');
			var documentName = type + '_' + subtype
			ProductPage.updateCSVLink(curveDataId, documentName);
			ProductPage.curveData.drawChart(type, subtype);
		});


		$('#app').on('CompareUpdated', (e, list) => {
			console.log(list);
			this.updateCompareButtons(list.items);
		});


	},

	"updateCompareButtons": function (compareList) {
		if (compareList == undefined) {
			$('.comparecontrol .regionlist button').each(function () {
				$(this).removeAttr('data-addtocomparebutton');
				$(this).attr('data-removecomparebutton', 'true').removeClass('selected');
			});
			return;
		};

		$('.comparecontrol .regionlist button').each(function () {
			const itemid = $(this).attr('data-item-id');
			console.log(compareList, compareList.indexOf(itemid));
			if (compareList.indexOf(itemid) > -1) {
				$(this).removeAttr('data-addtocomparebutton');
				$(this).attr('data-removecomparebutton', 'true').addClass('selected');
			} else {
				$(this).removeAttr('data-removecomparebutton');
				$(this).attr('data-addtocomparebutton', 'true').removeClass('selected');
			}
		});
    },

	"scrollHandler": function(e){
		var $anchors = $('[data-page-anchor]');

		const filterAnchors = $anchors.filter((i, el) => !(el.getBoundingClientRect().top + 500 > screen.height));

		const id = (filterAnchors[filterAnchors.length - 1]).id;

		$('#materials_nav').find('a').removeClass('active');
		$('#materials_nav').find('a[href="#'+ id +'"]').addClass('active');
		
	},

	"buildInPageNav": function () {
		var items = $('[data-page-anchor]');
		var data = [];

		items.map((i, el) => {
			data.push({
				"label": $(el).attr('data-page-anchor'),
				"id": $(el).attr('id')
			});
		});

		if (data.length > 0) {
			data.map((item) => {
				$('<a>', {
					"href": "#" + item.id,
					"data-tooltip": item.label,
					"title": item.label
				}).text(item.label).appendTo('#materials_nav')
			});
		}

		$('#materials_nav').addClass('ready')
    },

	"renderDistributors": function () {
		api.getDistributors(function(data){
			ProductPage.loadTemplate(window.api.template_path + "distributors.html", function (template) {
				$('[data-wtb-search]').val('');
				$('#distributorsTable').html(ejs.render(template, { data: data }));
			});
		});
    },

	"whereToBuySearch": function(searchTerm){
		searchTerm = searchTerm.toLowerCase();
		if (searchTerm == ''){
			$('#whereToBuy table tr').show();
			return;
		}

		$('#whereToBuy table tr').each(function(i, item){
			
			var searchable_fields = $(item).find('[data-searchable]');
			var showRow = false;

			if (searchable_fields.length == 0){
				showRow = true;
			} else {
		
				$(searchable_fields).each(function(j, field, el){
					var field_text = $(field).get(0).innerText.toLowerCase();
					if (field_text.indexOf(searchTerm) > -1){
						showRow = true;
					}
				});
			}

			if (!showRow){
				$(item).hide();
			} else {
				$(item).show();
			}
			
		});
	},

	"scrolledIntoView": function(elem) {
		
		var docViewTop = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();

		var elemTop = $(elem).offset().top;
		var elemBottom = elemTop + $(elem).height();

		return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
		
	},

	"loadCurveData": function () {
		var datatypes = ProductPage.curveData.getCurveDataTypes();
		for (var v = 0; v < datatypes.length; v++) {
			var sub_types = ProductPage.curveData.getCurveDataSubTypes(datatypes[v]);

			var optgroup = $('<optgroup>', {
				"label": datatypes[v]
			});

			var list = $('<div>', { class: "list" })
			for (var y = 0; y < sub_types.length; y++) {
				$('<button>', {
					class: '',
					"data-load-series": "true",
					"data-load-type": datatypes[v],
					"data-load-subtype": sub_types[y].title
				}).text(sub_types[y]).appendTo(list);

				$('<option>', {
					"value": sub_types[y].title,
					"data-type": datatypes[v],
					"data-curve-id": sub_types[y].id
				}).text(sub_types[y].title).appendTo(optgroup)
			}
			//list.appendTo('#curveData-tabs');
			optgroup.appendTo('#curveDataDD');
			$('#curveDataDD option').eq(0).attr("selected", "selected");
			$('#curveDataDD').trigger('change');
		}
		
	},
	"updateCSVLink": function (id, documentName) {
		
		var $link = $('[data-downloadcsv]'),
			$pdflink = $('[data-downloadpdf]'),
			base_url = $link.attr('data-baseurl'),
			title = $link.data('doc-title') || '';
			//console.log($link, $link.data('doc-title')) ;
			$pdflink.attr('data-doc-title', slugify(title + " " + documentName));
			$link.attr('href', '' + base_url + id + '?documentName=' + slugify(title + " " + documentName));
    },
	"loadTemplate": function (template, callback) {
		if (typeof ProductPage.template !== 'undefined') {
			callback(ProductPage.template);
			return;
		} else {

			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					ProductPage.template = this.responseText;
					callback(this.responseText);
				}
			}
			xhttp.open("GET", template, true);
			xhttp.send();
			return;
		}
	},
}

export default ProductPage;
