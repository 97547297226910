const RegistrationModel = {
    "el": null,
    "init": function(el) {
        this.el = el;
        this.setEvents();
        this.validateDisplay();
    },
    "setEvents": function() {
        const dismissEls = document.querySelectorAll('[data-dismiss-regdialog]');
        dismissEls.forEach((el, i) => {
            el.addEventListener("click", (e) => {
                this.dismiss();
            });
		});
       
    },
    "validateDisplay": function() {
      
        if ( sessionStorage.getItem("hideRegistrationPrompt") !== "true" && !window.member){
            this.show();
        }
    },
    "show": function(){

        this.el.classList.add("show")

    },
    "dismiss": function() {
        this.el.classList.remove("show");
        sessionStorage.setItem("hideRegistrationPrompt", "true");
    }
}
export default RegistrationModel;