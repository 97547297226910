import $ from 'jquery';
import MicroModal from 'micromodal';  // es6 module
import 'select2';
import validate from 'jquery-validation';
import validateUnobtrusive from 'jquery-validation-unobtrusive';
import mainNavigation from './misc/main-navigation';
import netZero from './views/netzero';
import productPage from './views/product';
import favoritesMenu from './misc/favorites';
import contactUs from './views/contact-us';
import compareTray from './views/compare-bar';
import registrationModel from './misc/registration-model';
import Language from './misc/language';
import { slugify } from './misc/helpers';
import applicationsPage from './views/applications';
import user from  './misc/user';
import api from './api/api';
import GDPRCookies from './misc/cookies';

import '../css/style.scss';
import '../css/dotcom/style.scss';

// $.validator.setDefaults({ ignore: null });

function isAlphaNumeric(str) {
	var code, i, len;

	for (i = 0, len = str.length; i < len; i++) {
		code = str.charCodeAt(i);
		if (!(code > 47 && code < 58) && // numeric (0-9)
			!(code > 64 && code < 91) && // upper alpha (A-Z)
			!(code > 96 && code < 123)) { // lower alpha (a-z)
			return false;
		}
	}
	return true;
};

//add validation methods
if ($.validator !== undefined){
	$.validator.unobtrusive.adapters.addBool("mandatory", "required");
	
	$.validator.addMethod("password", function(value, element, params) {
		// check for number
		var valid = /\d/.test(value);
			//check for one uppercase
			valid = valid && /[A-Z]/.test(value);
			//check for one lowercase
			valid = valid && /[a-z]/.test(value);
			//check for string length
			valid = valid && (value.length >= 8);
			//check for special char	
			valid = valid && (!isAlphaNumeric(value));
		return valid;
	}, "Must be at least 8 Characters: 1 uppercase, 1 lowercase, 1 number and 1 special character");

	$.validator.addMethod("validateemail", function(value, element) {

		if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
			return true;
		} else {
			return false;
		}
	}, "Please enter a valid Email.");

	$.validator.unobtrusive.adapters.addBool('password', "password");
	$.validator.unobtrusive.adapters.addBool('validateemail', "validateemail");
}

var site = {
	"MainNavigation": mainNavigation,
	"init": function () {
		favoritesMenu.init();
		user.init();

		site.bindEvents();
		site.multiFormSteps();
		site.inlineSVG();
		site.togglePassword();
		site.otp();
		site.select2();
		site.appRenderComplete();
		site.MainNavigation.init();
		site.compareBarScroll();
		site.reverseOrder();
		site.tabAccordion();

		GDPRCookies.init();

		window.addEventListener('DOMContentLoaded', function(){
			registrationModel.init(document.querySelector("[data-registration-model]"));
		})
		
		if ($('[data-contact-form]').length) {
			contactUs.init();
		}

		if ($(['data-netzero']).length) {
			netZero.init();
		}
		

		window.inlineSVG = site.inlineSVG;
		if ($('#product').length) {
			productPage.init();

			Language.loadLanguage("en", function (lang) {
				compareTray.init(lang);
			});
		}

		if ($('[data-filter-list]').length) {
			new applicationsPage;
		}

		api.getIndustriesList(function(data){
			var $industryList = $('[data-industry-select]');

			data.map((industry) => {
				$('<option />').text(industry.title).attr('value', industry.title).appendTo($industryList);
			});
			
			if ($industryList.attr('data-default') !== undefined) {
				var defaultValue = $industryList.attr('data-default');
				$industryList.val(defaultValue);
				$industryList.trigger('change');
			}
		});
		
	},

	"bindEvents": function () {
		$('[data-login-trigger]').on('click', function () {
			$('.menu-p1 li').removeClass('selected');
			MicroModal.show('login-modal');
		});


		$('#app').on('click', '[data-add-compare-toggle]', function (e) {
			$(this).parents('.comparecontrol').toggleClass('open');

			$('body').on('click.comparepopup', function () {
				$('.comparecontrol').removeClass('open');
				$('body').off('click.comparepopup');
			});
			e.stopPropagation();
		});


		$('[data-register-trigger]').on('click', function () {
			$('.menu-p1 li').removeClass('selected');
			$('#register-modal').removeClass("done");
			MicroModal.show('register-modal');
		});

		$('[data-industry-select]').on('change', function () {
			const area = $(this).val();
			const $interestList = $(this).parents('form').find('[data-interest-select]');
			const $interestListParent = $interestList.parents('.field-group');

			$interestListParent.find('.field-validation-error').removeClass('field-validation-error').empty();
			if (!area) {
				return;
			}
		

			if (area && area.toLowerCase() === "other"){
				//console.log(area, $interestListParent);
				$interestListParent.addClass('other');
				return;
			} else {
				$interestListParent.removeClass('other');
			}

			api.getInterestList(area, function(data){
				
				
				$interestList.each(function (i, list) {
					var $list = $(list);
					$list.empty();

					data.map((interest) => {
						$('<option />').text(interest.subIndustry).attr('value', interest.subIndustry).appendTo($list);
					});

					if ($list.attr('data-default') !== undefined) {

						var defaultValues = $list.attr('data-default').split(",");
						$list.val(defaultValues).trigger('change');
					
						window.setTimeout(function () {
							$list.removeAttr('data-default');
						}, 500);
					}
                })
				
			});
		});

		$(window).on('load.checkMembership', function (e) {
			if (!window.member) return;

			api.memberVisit({
				url: window.location.pathname + window.location.hash
			});
		});

		$('[data-changepassword-trigger]').on('click', function () {
			$('#change-password-modal').removeClass('done');
			$('#change-password-modal #change-password-error').text('');
			$('#change-password-modal input[name="CurrentPassword"]').val('');
			$('#change-password-modal input[name="Password"]').val('');
			$('#change-password-modal input[name="ConfirmPassword"]').val('');
			MicroModal.show('change-password-modal');
		});

		$('[data-progress-id]').on('click', function () {
			const $form = $(this).parents('form').eq(0);
			const step = $(this).attr('data-progress-id');;
			var valid;

			if (!$(this).hasClass('unlocked') || $(this).hasClass('done')){
				return;
			} else {
				
				valid = false;
				$form.validate();
				valid = $form.valid()
				if (valid) {
					$("#progressbar li").removeClass('done');
					$(this).addClass('done');
					$form.find('fieldset').hide().removeClass('active');
					$form.find('fieldset#' + step).show().addClass('active');
				}
            }
        });

		
		$('body').on('click', '[data-downloadpdf]', function(e){
			console.log(e.target, e.target.dataset["docTitle"]);
			const title = slugify(e.target.dataset["docTitle"] || "sabic-output") + ".pdf";
			if (window.html2pdf !== 'undefined'){
				var divToPrint = document.getElementById($(e.target).data('pdf-target'));
				const includeFooter = $(e.target).data('pdf-addfooter');
				
				$("body").addClass('show-pdf-modal');
				$(divToPrint).addClass('pdf-output');
				html2pdf().from(divToPrint,'element').set({
					margin: [20,10,10,10],
					mode: ['css', 'legacy'] ,
					filename: title,
					image:        { type: 'jpeg', quality: 0.98 },
					html2canvas:  { scale: 2, letterRendering: true, scrollX:0, scrollY:0 },
					jsPDF:        { unit: 'pt', format: 'letter', orientation: 'portrait' },
					pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
				  }).toPdf().get('pdf').then((pdf) => {
						if (includeFooter == true) return;
						var totalPages = pdf.internal.getNumberOfPages();
						pdf.setTextColor("#4D4D4D");

						for (let i = 1; i <= totalPages; i++) {
							pdf.setPage(i);

							pdf.setFontSize(8);
							pdf.text('2023 Copyright by SABIC. All rights reserved.', 30, pdf.internal.pageSize.getHeight() - 10, {});

							pdf.setFontSize(10);
							pdf.text('CHEMISTRY THAT MATTERS', pdf.internal.pageSize.getWidth()- 30, pdf.internal.pageSize.getHeight() - 10, {
								align: 'right'
							});
							

						}

						return pdf;
				  	}).save().then(function(){
					$(divToPrint).removeClass('pdf-output');
					$("body").removeClass('show-pdf-modal');
				});
			} else {
				console.log("PDF library unavailable!");
			}
		});


		$('body').on('click', '[data-downloadcanvas]', function(e){
			if (window.html2pdf !== 'undefined'){
				var title = "sabic-output.pdf";
				var divToPrint = document.getElementById($(e.target).data('pdf-target'));
				if ($(e.target).attr('data-chart-title')){
					title = $(e.target).attr('data-chart-title').replace(/[^a-z0-9]/gi, '_').toLowerCase() + ".pdf";
				}
				$("body").addClass('show-pdf-modal');
				$(divToPrint).addClass('pdf-output');
				html2pdf().set({
					margin: [2, 10, 0, 10],
					filename: title,
					jsPDF:  { orientation: 'landscape' }
				  }).from(divToPrint,'canvas').save().then(function(){
					$(divToPrint).removeClass('pdf-output');
					$("body").removeClass('show-pdf-modal');
				});
			} else {
				console.log("PDF library unavailable!");
			}
		});

	},

    "cloneToDoc" : function(node, doc) {
        var i, a, c, len;
        if (!doc) {
            doc = document;
        }
        var clone = doc.createElementNS(node.namespaceURI, node.nodeName);
        for (i = 0, len = node.attributes.length; i < len; ++i) {
            a = node.attributes[i];
            if (/^xmlns\b/.test(a.nodeName)) {
                continue;
            }
            clone.setAttributeNS(a.namespaceURI, a.nodeName, a.nodeValue);
        }
        for (i = 0, len = node.childNodes.length; i < len; ++i) {
            c = node.childNodes[i];
            clone.insertBefore(
                c.nodeType == 1 ? this.cloneToDoc(c, doc) : doc.createTextNode(c.nodeValue),
                null
            )
        }
        return clone;
    },

	
	"inlineSVG": function(){

		$("img.svg").each(function() {
            // Save item
            var img = $(this);
            // Check not loaded
            if (!img.hasClass("loaded")) {
                // Get the svg
                $.ajax({
                    url: img.attr("src"),
                    dataType: "xml",
                    type: "GET",
                    complete: function(data, status, xhr) {
                        // Its loaded
                        if (data.statusText === "OK" || data.statusText.toLowerCase() === "success") {
                            // It's correct
                            if (data.responseXML !== undefined) {
                                // Push to screen
                                var svg = data.responseXML.documentElement;
                                svg = site.cloneToDoc(svg, document);
                                window.svgRoot = svg;
                                img.parent().addClass("loaded");
                                img.addClass("loaded").after(svg);
                                delete window.svgRoot;
                            }
                        }
                    }
                });
            }
        });
	},

	"multiFormSteps": function () {
		//jQuery time
		var current_fs, next_fs, previous_fs; //fieldsets
		
		var valid = true, validationtarget=''; //default to not needing validation
		

		$(".next").click(function () {
			validationtarget = $(this).attr('data-validation-target');
			if (typeof validationtarget !== 'undefined' && $(validationtarget).length) {
				valid = false;
				$(validationtarget).validate();
				valid = $(validationtarget).valid()
			} 


			if (valid){
				current_fs = $(this).parent();
				next_fs = $(this).parent().next();

				//activate next step on progressbar using the index of next_fs
				$("#progressbar li").removeClass('done');
				$("#progressbar li").eq(next_fs.index() - 1).addClass("active").addClass('done').addClass('unlocked');
				//console.log(next_fs,next_fs.index()-1);
				//show the next fieldset
				next_fs.show().addClass('active');
				//hide the current fieldset with style
				current_fs.hide().removeClass('active');

			}
		});

		$(".previous").click(function () {

			current_fs = $(this).parent();
			previous_fs = $(this).parent().prev();

			//de-activate current step on progressbar
			$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

			//show the next fieldset
			previous_fs.show().addClass('active');
			//hide the current fieldset with style
			previous_fs.hide().removeClass('active');
			//show the previous fieldset
			previous_fs.show();
			
		});

		$(".submit").click(function () {
			validationtarget = $(this).attr('data-validation-target');
			if (typeof validationtarget !== 'undefined' && $(validationtarget).length) {
				valid = false;
				$(validationtarget).validate();
				valid = $(validationtarget).valid()
				//console.log(valid);
			} 

			if (!valid){
				return false;
			}
		})

		$('.menu-s2 a').on('click',function(){
			  // if it doesn't have a class, add it
				$('body').css('overflow','hidden');
		});

		$('button.modal__close').on('click',function(){
			$('body').css('overflow','auto');
		});
	},

	"togglePassword" : function() {
		$('.revealPassword img').on('click', function (e) {
			var $password = $(this).parents('.password').find('input');
			if ('password' == $password.attr('type')){
				$password.prop('type', 'text');
			}else{
				$password.prop('type', 'password');
			}
		});

		$("#forgot-password").hide();

		// Toggle between login and forgot password form
		$('.resetPassword').on('click',function(e){
			e.preventDefault();
			$('#loginForm').fadeOut(0);
			$("#forgot-password").fadeIn(350);
			$('#forgot-step1').show();
			$('#forgot-thanks').hide();
			$(".main_form h3").html('Reset Password');
		});

		$('#forgot-password .signin,.registration_login_link').on('click', function(e){
			e.preventDefault();
			$("#forgot-password").fadeOut(0);
			$('#loginForm').fadeIn(350);
			$(".main_form h3").html('Sign In');
		});

		//Toggle Login and Register modal
		$(".register-link").on('click',function(){
			$('#login-modal').removeClass('is-open');
		});

		$(".login-link").on('click',function(){
			$('#register-modal').removeClass('is-open');
		});
	},

	"otp": function() {
		$('.digit-group').find('input').each(function() {
			$(this).attr('maxlength', 1);
			$(this).on('keyup', function(e) {
				var parent = $($(this).parent());
				
				if(e.keyCode === 8 || e.keyCode === 37) {
					var prev = parent.find('input#' + $(this).data('previous'));
					
					if(prev.length) {
						$(prev).select();
					}
				} else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
					var next = parent.find('input#' + $(this).data('next'));
					
					if(next.length) {
						$(next).select();
					} else {
						if(parent.data('autosubmit')) {
							parent.submit();
						}
					}
				}
			});
		});
	},
	'select2': function() {
		$('.customSelect').each(function(){

			var hideSearch = ($(this).attr('data-hide-search') == "true")?-1:6;
			var renderOptgroup =  ($(this).attr('data-render-optgroup') == "true");
			var $select = $(this);
			
			const searcher = (data) => {
		
				$(this).data('term', data.term);
				return(data);
			}

			const sorter = (data) => {

				var query = $(this).data('term');
				if (query == undefined) return(data);
				return data.sort(function(a, b) {
				  return a.text.toLowerCase().indexOf(query.toLowerCase()) -
					b.text.toLowerCase().indexOf(query.toLowerCase());
				});

				
			}

			var options = {
				minimumResultsForSearch: hideSearch,
				sorter: sorter,
				language: {searching: searcher }
			}


			function optGroupRenderer(state){
			     if (!state.id) {
					return state.text;
				 }
			
				  var optgroup = $select.find("option[value='"+state.text+"']").parent()[0].label;
			
				  return $(`<strong>${optgroup}</strong> - <span>${state.text}</span>`);
			}
			if (renderOptgroup){
				options.templateSelection = optGroupRenderer;
			}

			$(this).select2(options);		
		});
	},
	'appRenderComplete' : function() {
		$('#app').on('app:renderComplete', function(e){
			site.select2();
			site.compareBarScroll();
		});
	},
	'compareBarScroll' : function () {
		setTimeout(() => {
			var compareItem;
			var x = window.matchMedia("(max-width: 700px)");

			function resizeWindow(x) {
				if (x.matches) { // If media query matches
					compareItem = $('#compare-tray .compare-list').width();

					$("#nav-next").on("click", function(){
						$(".compare-list").animate( { scrollLeft: '+='+compareItem }, 200);
						
					});
					
					$("#nav-prev").on("click", function(){
						$(".compare-list").animate( { scrollLeft: '-='+compareItem }, 200);
					});

				} else {
					$("#nav-next").on("click", function(){
						$(".compare-list").animate( { scrollLeft: '+=500' }, 200);
						
					});
					
					$("#nav-prev").on("click", function(){
						$(".compare-list").animate( { scrollLeft: '-=500' }, 200);
					});
				}
			}
			
			
			resizeWindow(x) // Call listener function at run time
			x.addListener(resizeWindow) // Attach listener function on state changes

			$(".compare-list").on('scroll', function() {
				var $val = $(this).scrollLeft();
				/** Hide buttons if no more items to scroll **/
				// if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
				// 		$("#nav-next").fadeOut();
				// 	} else {
				// 	$("#nav-next").fadeIn();
				// }
	
				// if($val == 0){
				// 	$("#nav-prev").fadeOut();
				// } else {
				// 	$("#nav-prev").fadeIn();
				// }
			});
			
			
	
			const scrollContainer = document.querySelector(".compare-list");
			if (scrollContainer !== null){ 
				scrollContainer.addEventListener("wheel", (evt) => {
						evt.preventDefault();
						scrollContainer.scrollLeft += evt.deltaY;
				});
			}
		}, "500")
		
	},
	'reverseOrder' : function () {
		var item_count = $('.sub-content--colors .colors .circle').length;
		for( var i = 0; i < item_count; i++ ) {
			$('.sub-content--colors .colors .circle').eq( i ).css( 'z-index', item_count - i );
		}
	},
	'tabAccordion' : function () {
		const labels = document.querySelectorAll(".accordion-item__label");
		const tabs = document.querySelectorAll(".accordion-tab");
	
		function toggleShow() {
			const target = this;
			const item = target.classList.contains("accordion-tab") ?
				target :
				target.parentElement;
			const group = item.dataset.actabGroup;
			const id = item.dataset.actabId;
			let windowsize = $(window).width();
	
			tabs.forEach(function (tab) {
				if (tab.dataset.actabGroup === group) {
					if (tab.dataset.actabId === id) {
						tab.classList.add("accordion-active");
					} else {
						tab.classList.remove("accordion-active");
					}
				}
			});
			
			labels.forEach(function (label) {
				const tabItem = label.parentElement;
	
				if (tabItem.dataset.actabGroup === group) {
					if (tabItem.dataset.actabId === id) {
						tabItem.classList.add("accordion-active");
					} else {
						tabItem.classList.remove("accordion-active");
					}
				}
			});
		}
	
		$('.accordion-item__label').on('click', function(){
			$(this).parent().toggleClass('accordion-active');
		});
	
		// labels.forEach(function (label) {
		// 	label.addEventListener("click", toggleShow);
		// });
	
		tabs.forEach(function (tab) {
			tab.addEventListener("click", toggleShow);
		});
	}
}

site.init();